body {
	font-family: 'Source Sans Pro', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
}
 body, html {
  height: 100%;
 }
 header .navbar-brand {
  padding: 3px 20px 1px 20px;
  display: block;
  height: auto;
}
header.app-header.navbar {
  padding: 0;
}
header .navbar-brand img {
  width: auto;
  height: 50px;
  padding: 12px 0;
}
body #app > div, body #app > nav {
  width: 100%;
  max-width: inherit;
  margin-bottom: 0;
}
body #app > div {
  padding: 0;
}
body #app #logo, body.welcome #logo {
  background: rgba(13,90,167,1);
  background: radial-gradient(circle, rgba(61,178,192,1) 0%, rgba(13,90,167,1) 100%);
  width:100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
body #app #logo > img {
  width: auto;
  height: auto;
  max-height: 100%;
  margin: auto;
  display: block;
}
.demo, .logged-in-as {
  margin: 0 !important;
  border-radius: 0 !important;
}
div.expired {
  background: radial-gradient(circle, #07A59C, #0A4E8E);
  background: -moz-radial-gradient(circle, #07A59C, #0A4E8E);
  background-repeat: no-repeat;
}

div.container {
  max-width: 100%;
}
.enlace{
  display: inline-block;
  vertical-align: middle;
  margin-left: 40;
  font-size: 18px;
  font-weight: 500;
  color: #717d84;
}
header.app-header > .container {
  padding-left: 0;
  padding-right: 0;
  border: none;
}
body header.app-header.navbar {
    background: linear-gradient(90deg, rgb(3, 92, 152) 0%, rgb(98, 171, 218) 100%);
    border: none;
}
header.app-header .navbar-brand {
  width: 200px;
  background: #fff;
}
 header.app-header.navbar {
  background: #fff;
}
header.app-header.navbar .navbar-nav .nav-link {
  color: #fff;
}
header.app-header .user-info {
  color: #fff;
  font-size: 100%;
  background: #005b99;
  padding: 7px 12px;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  box-shadow: 1px 1px 0 #ee8222;
}
header.app-header .nav-item .nav-link > .img-avatar, header.app-header .nav-item .avatar.nav-link > img {
  width: 25px;
  height: 25px;
}
.sidebar nav.sidebar-nav.ps {
  background: #0f5d9b;
}
.sidebar nav.sidebar-nav .nav-title, .sidebar nav.sidebar-nav a.nav-link,
.sidebar nav.sidebar-nav .nav-link .nav-icon {
  color: #fff;
}
body.sidebar-minimized .sidebar nav.sidebar-nav .nav-title, .sidebar nav.sidebar-nav a.nav-link,
body.sidebar-minimized .sidebar nav.sidebar-nav .nav-link .nav-icon {
  color: #0f5d9b;
}
.sidebar nav.sidebar-nav .nav-link {
  font-size: 18px !important;
}
  div.sidebar nav.sidebar-nav ul.nav-dropdown-items {
    margin-right: 0 !important;
  }
  .sidebar nav.sidebar-nav .nav-dropdown-items .nav-link {
    font-size: 16px !important;
  }
.sidebar nav.sidebar-nav .nav-title, .sidebar nav.sidebar-nav a.nav-link::before {
  color: #fff;
  font-weight: normal;
}
ol.breadcrumb {
    padding:7px 30px; 
}
.widget-metric_6 {
  display: flex;
  align-items: center;
}
.widget-metric_6 .value em {
  font-size: 12px;
  color: #a0aeba;
}
.widget-metric_6 span.icon-wrapper {
  width: 35px;
  height: 35px;
}
.widget-metric_6 span.icon-wrapper i {
  line-height: 35px;
  font-size: 15px;
}
div.panel .panel-heading {
  padding:  7px 15px;
}
.panel-body .widget {
  padding: 10px 11px;
  line-height: 1;
}
div.custom-radio label {
  margin-bottom: 0;
}
.widget-metric_6 div.right {
  padding: 0 15px;

}
a.fa.fa-info-circle.position-absolute {
  top: 10px;
  right: 15px;
}
.widget-metric_1 span.icon-wrapper {
  width: 40px;
  height: 40px;
}
.widget-metric_1 span.icon-wrapper i {
  line-height: 40px;
}
.panel-title .enlace {
  font-size: 14px;
}
.nav-tabs .nav-link.active {
  z-index: 1;
  position: relative;
}
.nav-tabs .nav-link.active::before {
  content: '';
  display: block;
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 100%;
  height: 1px;
  background: #fff;
}
.nav.nav-tabs .nav-item {
  margin-right: 1px;
}
ul.nav.nav-tabs .nav-item .nav-link {
  background:#0f5d9b !important;
  color: #fff !important;
}
.nav.nav-tabs .nav-item a.nav-link.active {
  background: #fff !important;
  color: #0f5d9b !important;
}
table.table.table-minimal>tbody>tr>td,
table.table.table-minimal>tbody>tr>th {
  padding:7px 15px; 
}
#persona a.btn.btn-round.btn-info,
#grupo a.btn.btn-round.btn-info,
#servicio a.btn.btn-round.btn-info {
  padding: 0.25rem 0.5rem;
  font-size: 0.765625rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}
div ul.activity-list>li > div {
  padding: 0 30px;
}
div ul.activity-list>li {
  padding: 7px 0;
}
div ul.activity-list>li span.timestamp {
  width: 100%;
}
#persona a.btn.btn-round.btn-info i,
#grupo a.btn.btn-round.btn-info i,
#servicio a.btn.btn-round.btn-info i {
	color: inherit;
}
.sidebar-minimized div.sidebar nav.sidebar-nav .nav-title,
div.sidebar nav.sidebar-nav a.nav-link,
.sidebar-minimized div.sidebar nav.sidebar-nav .nav-link .nav-icon {
	color: #fff;
}
div.sidebar .nav-link.active {
	background: none;
}
div.sidebar nav.sidebar-nav .nav-item.nav-dropdown a.nav-link::before {
  content: none;
  opacity: 1;
}
div .table th, div .table td {
  padding: 7px 10px;
  vertical-align: middle;
}
footer {
  background: #fff;
}
footer .img-footer {
    height: 45px;
    width: auto;
}
footer div.movil-center img.img-footer {
  display: block;
  margin: 0 auto 5px auto;
}